import { inProduction } from "../config";
import { inBrowser } from "./utils/utils";
import { captureException, setUser } from "@sentry/nextjs";
import { getOrg } from "./utils/site";
import * as amplitude from "@amplitude/analytics-browser";
import { Experiment } from "@amplitude/experiment-js-client";

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
const AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY;

if (inBrowser && AMPLITUDE_API_KEY) {
  amplitude.init(AMPLITUDE_API_KEY, {
    autocapture: {
      attribution: true,
      elementInteractions: true,
      fileDownloads: true,
      formInteractions: true,
      pageViews: false,
      sessions: true,
    },
  });
}

export let experimentClient:
  | ReturnType<typeof Experiment["initializeWithAmplitudeAnalytics"]>
  | undefined;

if (inBrowser && AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY) {
  experimentClient = Experiment.initializeWithAmplitudeAnalytics(
    AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY || ""
  );
}

export const isExperimentLoaded = new Promise(async (resolve, reject) => {
  try {
    if (experimentClient && inBrowser) {
      await experimentClient.start();
      resolve(undefined);
    }
  } catch (error) {
    reject(error);
  }
});

type LogFunction = (event: string, props?: any) => Promise<any>;

export const logEvent: LogFunction = async (event, props) => {
  if (inBrowser && AMPLITUDE_API_KEY) {
    amplitude.track(event, {
      ...(props ? props : {}),
      org: getOrg(),
    });
  } else {
    console.log("TRACK", event, { ...(props ? props : {}), org: getOrg() });
  }
};

export const identify = async (
  id: string | undefined,
  props: any = {}
): Promise<any> => {
  const propsWithOrg = { ...props, org: getOrg() };
  if (inBrowser && inProduction) {
    const hotjar = global.window.hj;

    if (hotjar) {
      hotjar("identify", id || props.resparkClientId, propsWithOrg);
    }
    if (id) {
      amplitude.setUserId(id);
    }
    const identifyObj = new amplitude.Identify();

    Object.keys(propsWithOrg)
      .filter((key) => !!propsWithOrg[key])
      .forEach((key) => {
        identifyObj.set(key, propsWithOrg[key]);
      });

    amplitude.identify(identifyObj);

    try {
      id
        ? setUser({ id, ...propsWithOrg })
        : setUser({ id: propsWithOrg.resparkClientId, ...propsWithOrg });
    } catch (e) {
      captureException(e);
    }
  } else {
    console.log("Identify", id, propsWithOrg);
  }
  return Promise.resolve();
};

type CalendlyEvent = {
  event:
    | "calendly.profile_page_viewed"
    | "calendly.event_type_viewed"
    | "calendly.date_and_time_selected"
    | "calendly.event_scheduled";
  payload: any;
};

function isCalendlyEvent(e: MessageEvent<CalendlyEvent>) {
  return (
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.") === 0
  );
}

if (typeof window !== "undefined") {
  window.addEventListener(
    "message",
    function (event: MessageEvent<CalendlyEvent>) {
      if (isCalendlyEvent(event)) {
        logEvent(event.data.event);
      }
    }
  );
}
