import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import styles from "./overlay.module.css";
import { FC, ReactNode, useEffect } from "react";
import classNames from "classnames";
import Image from "next/image";
import { useWebTranslation } from "translations";
import { Typography } from "ui-kit/Typography";
import InnerLayout from "components/InnerLayout/InnerLayout";

interface OverlayProps {
  display: { title: string; content: ReactNode; full?: boolean } | null;
  onRemove: () => void;
}

const Overlay: FC<OverlayProps> = ({ display, onRemove }) => {
  const { t } = useWebTranslation();

  useEffect(() => {
    const onEnterHandler = (event: HTMLElementEventMap["keydown"]) => {
      if (event.key === "Escape") {
        onRemove();
      }
    };
    document.body.addEventListener("keydown", onEnterHandler);

    return () => {
      document.body.removeEventListener("keydown", onEnterHandler);
    };
  }, [onRemove]);

  return (
    <>
      <div
        className={classNames(styles.overlay, display && styles.displayOverlay)}
        onClick={() => onRemove()}
      />
      <div
        className={classNames(
          styles.slip,
          display?.content && styles.displaySlip,
          display?.full ? styles.full : styles.box
        )}
      >
        {display && (
          <InnerLayout backgroundColor="white" hideHeader>
            <FlexRow
              justifyContent="space-between"
              alignItems="center"
              className={styles.header}
            >
              <Typography variant="body1">
                <strong>{display.title}</strong>
              </Typography>
              <div
                onClick={() => onRemove()}
                style={{
                  cursor: "pointer",
                  paddingLeft: 16,
                  display: "flex",
                }}
              >
                <Image
                  src="/images/icons/close-grey.svg"
                  alt={t("global.close")}
                  width={28}
                  height={28}
                  onClick={() => onRemove()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </FlexRow>
            <FlexCol className={styles.content}>{display.content}</FlexCol>
          </InnerLayout>
        )}
      </div>
    </>
  );
};

export default Overlay;
