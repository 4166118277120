import { useIntercomReady } from "hooks/useIntercomReady";
import { useRouter } from "next/router";
import { useWebTranslation } from "translations";
import { Button } from "ui-kit/Button";
import { getContactEmail } from "utils/site";
import styles from "./ContentfulC2A.module.css";
import { useCallback } from "react";

export const MessageButton = () => {
  const { t } = useWebTranslation();
  const router = useRouter();

  const { status: intercomStatus } = useIntercomReady();

  const onPress = useCallback(() => {
    if (intercomStatus === "resolved") {
      if (window.Intercom) {
        window.Intercom("show");
      }
    } else {
      router.push(`mailto: ${getContactEmail()}`);
    }
  }, [intercomStatus, router]);

  return (
    <Button
      variant="outlined"
      textColor="blue300"
      title={
        intercomStatus === "resolved"
          ? t("c2a.send_message")
          : t("c2a.email_us")
      }
      icon="chat-lines"
      loading={intercomStatus === "pending"}
      iconFirst
      onPress={onPress}
      className={styles.button}
    />
  );
};
