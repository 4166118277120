import React, { FC, useState } from "react";
import styles from "./InsurancePackagesTable.module.css";
import {
  useTranslationsReady,
  useWebTranslation,
} from "../../../../translations";
import { replaceCommonMarkup } from "../../../../utils/utils";
import Image from "next/image";
import Icon from "../../../../ui-kit/Icon";
import useBreakPoints from "../../../../hooks/useMediaQuery";
import { Typography } from "../../../../ui-kit/Typography";
import { PackageConfig } from "../types";
import PackageCard from "components-shared/PackageCard";
import LassieLoader from "ui-kit/LassieLoader";
import Colors from "ui-kit/colors";
import Link from "next/link";
import classNames from "classnames";

type InsurancePackagesTableProps = {
  config: PackageConfig;
};

const InsurancePackagesTable: FC<InsurancePackagesTableProps> = ({
  config,
}) => {
  const translationsReady = useTranslationsReady();
  const { t } = useWebTranslation();
  const [openedRow, setOpenedRow] = useState<number>();
  const { isTablet } = useBreakPoints();

  const rowTitleClickHandler = (index: number) =>
    setOpenedRow((curOpenedRow) =>
      index === curOpenedRow ? undefined : index
    );

  const formatProperty = (value?: string | boolean) => {
    if (!value) {
      return (
        <Image
          src="/images/icons/close-red.svg"
          alt={t("global.no")}
          width={20}
          height={20}
          style={{ display: "flex" }}
        />
      );
    }
    if (value === true) {
      return (
        <Image
          src="/images/icons/checkmark-green.svg"
          alt={t("global.yes")}
          width={20}
          height={20}
          style={{ display: "flex" }}
        />
      );
    }

    return replaceCommonMarkup(t(value));
  };

  const legendView = config.benefits.map((item, index) => {
    const isOpen = index === openedRow;

    return (
      <div
        key={item.title}
        className={classNames(
          styles.legendItem,
          isOpen && styles.descriptionOpened
        )}
        style={{ gridRow: 5 + index * 2 }}
      >
        <div
          className={styles.legendItemTitle}
          onClick={rowTitleClickHandler.bind(this, index)}
        >
          <Typography style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {t(item.title)}
          </Typography>
          <Icon
            name={isOpen ? "chevron-up" : "chevron-down"}
            size={20}
            color={Colors.blue300}
            styles={{ position: "absolute", right: -10 }}
          />
        </div>
        <p className={styles.legendItemDescription}>
          {t(item.description ?? "")}
        </p>
      </div>
    );
  });

  const packagesView = config.packages.map((pack, index) => {
    const gridColumn = (isTablet ? 1 : 2) + index;
    let currentGridRow = 3;

    return (
      <div key={pack.title} className={styles.package} style={{ gridColumn }}>
        <div
          className={styles.packageBackground}
          style={{
            gridColumn,
            gridRow: `4 / ${config.benefits.length * 2 + 6}`,
          }}
        />
        <div
          className={styles.packageCard}
          style={{
            gridColumn,
          }}
        >
          <PackageCard pack={pack} />
        </div>
        {config.benefits.map((benefit) => {
          currentGridRow += 2;
          return (
            <div
              key={benefit.name}
              className={styles.packageProp}
              style={{ gridRow: currentGridRow, gridColumn }}
            >
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                {formatProperty(pack.benefitIncludes[benefit.name])}
              </Typography>
            </div>
          );
        })}
        <div
          className={styles.packageProp}
          style={{ gridRow: currentGridRow + 2, gridColumn, paddingLeft: 2 }}
        >
          <Link
            href={t(pack.ipid)}
            className={styles.factSheet}
            target="_blank"
          >
            <Icon name="document-check" size={20} color={Colors.grey500} />
            <Typography color="grey500">
              {t("insurance_packages.item_info")}
            </Typography>
          </Link>
        </div>
      </div>
    );
  });
  if (!translationsReady) {
    return (
      <div
        style={{
          height: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LassieLoader size={80} />
      </div>
    );
  }
  return (
    <div>
      <article
        style={{
          gridTemplateColumns: `repeat(${
            config.packages.length + (isTablet ? 0 : 1)
          }, minmax(100px, 1fr))`,
          gridTemplateRows: `repeat(${config.benefits.length + 2}, auto)`,
        }}
        className={styles.packages}
      >
        <section className={styles.legend}>{legendView}</section>
        <section className={styles.packagesItems}>{packagesView}</section>
      </article>
    </div>
  );
};

export default InsurancePackagesTable;
