import React, { FC } from "react";
import styles from "./ReviewsSection.module.css";
import classNames from "classnames";
import { i18n } from "next-i18next";
import { contentfulLocale } from "../../../../utils/utils";

type ReviewsSectionProps = {};

const ReviewsSection: FC<ReviewsSectionProps> = () => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [global.window?.Trustpilot]);

  const locale = contentfulLocale(i18n?.language ?? "en_GB");

  return (
    <div
      ref={ref}
      className={classNames("trustpilot-widget", styles.widget)}
      data-locale={locale}
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="60dda23d8223de0001953209"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="4,5"
      data-review-languages={locale}
      style={{ height: 140 }}
    />
  );
};

export default ReviewsSection;
