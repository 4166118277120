import { useEffect, useState } from "react";

type UseBreakPoints = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const global =
  typeof window === "undefined"
    ? {
        matchMedia: () => ({
          matches: true,
          addListener: () => {},
          addEventListener: () => {},
          removeEventListener: () => {},
        }),
      }
    : window;

export default function useBreakPoints(): UseBreakPoints {
  const [matchesMobile, setMatchesMobile] = useState<boolean>(
    global.matchMedia(`(max-width: 480px)`).matches
  );
  const [matchesTablet, setMatchesTablet] = useState<boolean>(
    global.matchMedia(`(max-width: 768px)`).matches
  );
  const [matchesDesktop, setMatchesDesktop] = useState<boolean>(
    global.matchMedia(`(min-width: 1000px)`).matches
  );
  useEffect(() => {
    const mobileQuery = global.matchMedia(`(max-width: 480px)`);
    const tabletQuery = global.matchMedia(`(max-width: 768px)`);
    const desktopQuery = global.matchMedia(`(min-width: 1000px)`);

    setMatchesMobile(mobileQuery.matches);
    setMatchesTablet(tabletQuery.matches);
    setMatchesDesktop(desktopQuery.matches);
    const mobileHandler = (event: any) => setMatchesMobile(event.matches);
    const tabletHandler = (event: any) => setMatchesTablet(event.matches);
    const desktopHandler = (event: any) => setMatchesDesktop(event.matches);

    // Due to addEventListener not being present in old browsers
    if (mobileQuery.addEventListener) {
      mobileQuery.addEventListener("change", mobileHandler);
      tabletQuery.addEventListener("change", tabletHandler);
      desktopQuery.addEventListener("change", desktopHandler);
    } else if (mobileQuery.addListener) {
      mobileQuery.addListener(mobileHandler);
      tabletQuery.addListener(tabletHandler);
      desktopQuery.addListener(desktopHandler);
    }
    return () => {
      if (mobileQuery.removeEventListener) {
        mobileQuery.removeEventListener("change", mobileHandler);
        tabletQuery.removeEventListener("change", tabletHandler);
        desktopQuery.removeEventListener("change", desktopHandler);
      }
    };
  }, []);
  return { isMobile: matchesMobile, isTablet: matchesTablet, isDesktop: matchesDesktop };
}
