import React, { FC, Fragment, ReactElement } from "react";
import styles from "./Tabs.module.css";
import { Typography } from "../Typography";
import classNames from "classnames";
import Icon from "../Icon";
import Colors from "../colors";

type TabItemProps = {
  active: boolean;
  onPress: () => void;
  label: string;
  icon?: string;
};

const TabItem = ({ active, label, icon, onPress }: TabItemProps) => {
  return (
    <div className={styles.option} onClick={onPress}>
      <Typography
        style={{ transition: "all 300ms" }}
        variant="h6"
        color={active ? "blue300" : "grey500"}
      >
        {icon && (
          <Icon
            size={24}
            color={active ? Colors.blue300 : "#828282"}
            name={icon}
          />
        )}
        {label}
      </Typography>
    </div>
  );
};

type TabsProps = {
  items: ReactElement[];
  tabs: { label: string; icon?: string; value: string }[];
  onChangeTabs: (value: unknown, index: number) => void;
  index: number;
};

const Tabs: FC<TabsProps> = ({ items, tabs, onChangeTabs, index }) => (
  <div className={styles.outer}>
    <div className={styles.container}>
      <div className={styles.track}>
        <div
          className={styles.button}
          style={{
            width: `${100 / tabs.length}%`,
            transform: `translateX(calc(${index * 100}%))`,
          }}
        />
        <div className={styles.options}>
          {tabs.map((tab, i) => (
            <TabItem
              key={tab.value}
              active={index === i}
              onPress={() => {
                onChangeTabs(tab.value, i);
              }}
              label={tab.label}
              icon={tab.icon}
            />
          ))}
        </div>
      </div>
    </div>
    {Boolean(items.length) && (
      <div className={classNames(styles.content)}>
        {items.map((component, i) => (
          <Fragment key={i}>{i === index ? component : null}</Fragment>
        ))}
      </div>
    )}
  </div>
);

export default Tabs;
