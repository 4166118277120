import { useRouter } from "next/router";
import { ImageProps } from "next/legacy/image";

import AppStoreSv from "../../public/images/storeIcons/appstoreSv.webp";
import AppStoreDe from "../../public/images/storeIcons/appstoreDe.webp";
import AppStoreEn from "../../public/images/storeIcons/appstoreEn.webp";
import AppStoreFr from "../../public/images/storeIcons/appstoreFr.svg";

import GooglePlaySv from "../../public/images/storeIcons/googleplaySv.webp";
import GooglePlayDe from "../../public/images/storeIcons/googleplayDe.webp";
import GooglePlayEn from "../../public/images/storeIcons/googleplayEn.webp";
import GooglePlayFr from "../../public/images/storeIcons/googleplayFr.svg";

type ImageSrc = ImageProps["src"];

enum Locale {
  sv = "sv",
  de = "de",
  en = "en",
  fr = "fr",
}

type Props = Partial<Record<Locale, ImageSrc>>;

const useLocalisedImageSrc = (props: Props): ImageSrc => {
  const router = useRouter();
  const locale = router.locale as Locale;
  return props[locale] ?? props[Locale.en] ?? Object.values(props)[0];
};

export const useAppStoreImageSrc = () => {
  const appStoreImageSrc = useLocalisedImageSrc({
    sv: AppStoreSv,
    de: AppStoreDe,
    en: AppStoreEn,
    fr: AppStoreFr,
  });
  const playMarketImageSrc = useLocalisedImageSrc({
    sv: GooglePlaySv,
    de: GooglePlayDe,
    en: GooglePlayEn,
    fr: GooglePlayFr,
  });
  return { appStoreImageSrc, playMarketImageSrc };
};
