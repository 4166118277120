import { ResumeDraftState } from "components/Signup/store/state/types";
import { isLocalStorageAvailable } from "utils/utils";

/**
 * Used in the case of resuming preview of quote from CTA on landing page
 */
export const resumePreviewStore = {
  save: (signUpState: ResumeDraftState): void => {
    if (
      isLocalStorageAvailable() &&
      JSON.stringify(signUpState) !==
        window.localStorage.getItem("resumePreviewState")
    )
      window.localStorage.setItem(
        "resumePreviewState",
        JSON.stringify({ ...signUpState })
      );
  },

  load: (): ResumeDraftState => {
    const state = isLocalStorageAvailable()
      ? JSON.parse(window.localStorage.getItem("resumePreviewState") as string)
      : undefined;

    if (state) {
      // state.inProgress = false;
    }

    return state;
  },

  remove: (): void => {
    if (isLocalStorageAvailable()) {
      window.localStorage.removeItem("resumePreviewState");
    }
  },
};
