import { useEffect, useState } from "react";

type Status = "pending" | "resolved" | "rejected";

const RETRY_INTERVAL_IN_MS = 300;

export function usePolling(condition: () => boolean, maxWait: number) {
  const [status, setStatus] = useState<Status>("pending");

  useEffect(() => {
    if (condition()) {
      setStatus("resolved");
      return;
    }

    setStatus("pending");

    const t = Date.now();

    const timer = setInterval(() => {
      if (condition()) {
        setStatus("resolved");
        clearInterval(timer);

        return;
      }

      if (Date.now() - t >= maxWait) {
        setStatus("rejected");
        clearInterval(timer);
      }
    }, RETRY_INTERVAL_IN_MS);

    return () => {
      clearInterval(timer);
    };
  }, [condition, maxWait]);

  return status;
}
