import { FC } from "react";
import Colors from "ui-kit/colors";
import { Typography } from "ui-kit/Typography";

interface PackageCardTabProps {
  text: string;
  color?: string;
}

const PackageCardTab: FC<PackageCardTabProps> = ({ text, color }) => {
  return (
    <Typography
      variant="body4"
      style={{
        backgroundColor: color ?? Colors.sun300,
        margin: 0,
        borderRadius: "24px 24px 0 0",
        padding: "4px 0",
        textAlign: "center",
      }}
    >
      <strong>{text}</strong>
    </Typography>
  );
};

export default PackageCardTab;
