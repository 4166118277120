import React, { FC } from "react";
import styles from "./ResponsiveGutter.module.css";

type GutterSize =
  | 0
  | 0.25
  | 0.5
  | 1
  | 1.5
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | number;

export interface GutterProps {
  size: GutterSize;
  horizontal?: boolean;
}

const Gutter: FC<GutterProps> = ({ size, horizontal }) => (
  <div style={{ [horizontal ? "width" : "height"]: size * 8 }} />
);

interface ResponsiveGutterProps {
  mobile?: GutterSize;
  tablet?: GutterSize;
  desktop?: GutterSize;
  horizontal?: boolean;
}

const ResponsiveGutter: FC<ResponsiveGutterProps> = ({
  mobile,
  tablet,
  desktop,
  horizontal,
}) => {
  return (
    <>
      <div className={styles.mobile}>
        <Gutter size={mobile ?? 0} horizontal={horizontal} />
      </div>

      <div className={styles.tablet}>
        <Gutter size={tablet ?? mobile ?? 0} horizontal={horizontal} />
      </div>

      <div className={styles.desktop}>
        <Gutter
          size={desktop ?? tablet ?? mobile ?? 0}
          horizontal={horizontal}
        />
      </div>
    </>
  );
};

ResponsiveGutter.defaultProps = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
  horizontal: false,
};

export default ResponsiveGutter;
