import { usePolling } from "./usePolling";

const isIntercomReady = () => {
  return (
    typeof window !== "undefined" &&
    window.Intercom !== undefined &&
    // This is probably among the worst hacks I've done in my professional
    // carrer but yet I'm oddly satisfied by it. Since there is no `ready`
    // mechanism for Intercom, the only way I could think of was this.

    // The snippet to initialize `Intercom` bootstraps the `window.Intercom`
    // function (with a total length of 27 at the time of writing). Once the
    // script loads, it replaces the function and reaches a length of 4033.

    // ¯\_(ツ)_/¯
    window.Intercom.toString().length > 50
  );
};

export function useIntercomReady() {
  const status = usePolling(isIntercomReady, 3000);

  return { status };
}
