import { FC, ReactNode } from "react";
import styles from "./PackageCardContent.module.css";
import { FlexCol } from "ui-kit/Layout/Flex";
import classNames from "classnames";

interface PackageCardContentProps {
  children: ReactNode;
  rounded?: boolean;
}

const PackageCardContent: FC<PackageCardContentProps> = ({
  children,
  rounded,
}) => (
  <FlexCol className={classNames(styles.container, rounded && styles.rounded)}>
    {children}
  </FlexCol>
);

export default PackageCardContent;
