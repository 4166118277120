import React, { FC } from "react";
import Link from "next/link";
import styles from "./index.module.css";
import { getAppStoreUrl, getPlayMarketUrl } from "../../utils/site";
import Image from "next/image";
import { useAppStoreImageSrc } from "../../translations/app-store-images";
import Gutter from "ui-kit/Gutter";

const StoreIcons: FC = () => {
  const { appStoreImageSrc, playMarketImageSrc } = useAppStoreImageSrc();

  return (
    <div className={styles.container}>
      <Link href={getAppStoreUrl()}>
        <Image
          src={appStoreImageSrc}
          width={150}
          height={50}
          alt="Appstore"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Link>
      <Gutter size={1} horizontal />
      <Link href={getPlayMarketUrl()}>
        <Image
          alt="Google Play"
          src={playMarketImageSrc}
          width={168}
          height={50}
          style={{
            objectFit: "cover",
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Link>
    </div>
  );
};

export default StoreIcons;
