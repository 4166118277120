import React, { FC } from "react";
import { contentfulLocale } from "../utils/utils";
import { i18n } from "next-i18next";

type TrustPilotStarsProps = {
  fullWidth?: boolean;
};

const TrustPilotStars: FC<TrustPilotStarsProps> = ({ fullWidth }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const height = "24px";
  const width = fullWidth ? "100%" : "300px";

  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget"
      data-locale={contentfulLocale(i18n?.language ?? "en_GB")}
      data-template-id="5406e65db0d04a09e042d5fc"
      data-businessunit-id="60dda23d8223de0001953209"
      data-style-height={height}
      data-style-width={width}
      data-style-alignment="center"
      data-theme="light"
      style={{ height, width, marginTop: 4 }}
    />
  );
};
export default TrustPilotStars;
