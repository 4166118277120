import React, { FC, Fragment, useState } from "react";
import { useWebTranslation } from "../../../../translations";
import styles from "./MobileFullPackages.module.css";
import Image from "next/image";
import Gutter from "../../../../ui-kit/Gutter";
import { Typography } from "../../../../ui-kit/Typography";
import Icon from "../../../../ui-kit/Icon";
import { replaceCommonMarkup } from "../../../../utils/utils";
import Tabs from "../../../../ui-kit/Tabs/Tabs";
import { PackageConfig } from "../types";
import PackageCard from "components-shared/PackageCard";
import Colors from "ui-kit/colors";
import Link from "next/link";
import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import { Button } from "ui-kit/Button";

type MobileFullPackagesProps = {
  config: PackageConfig;
  expanded?: boolean;
  defaultSelectedIndex?: number;
};

const MobileFullPackages: FC<MobileFullPackagesProps> = ({
  config,
  expanded,
  defaultSelectedIndex,
}) => {
  const { t } = useWebTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(!!expanded);
  const [openRowIndex, setOpenRowIndex] = useState<number>();
  const popularIndex = config.packages.findIndex((p) => p.popular);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    defaultSelectedIndex ?? Math.max(popularIndex, 0)
  );
  const pack = config.packages[selectedIndex] ?? [];

  const formatProperty = (value?: string | boolean) => {
    if (!value) {
      return (
        <Image
          src="/images/icons/close-red.svg"
          alt={t("global.no")}
          width={20}
          height={20}
          style={{ display: "flex" }}
        />
      );
    }
    if (value === true) {
      return (
        <Image
          src="/images/icons/checkmark-green.svg"
          alt={t("global.yes")}
          width={20}
          height={20}
          style={{ display: "flex" }}
        />
      );
    }

    return replaceCommonMarkup(t(value));
  };

  const renderPackage = (p: typeof pack) => {
    const numberOfNonExpandedRows = 5;
    return (
      <div className={styles.container}>
        {[
          ...config.benefits.map((benefit, index) => {
            const isOpen = openRowIndex === index;

            return (
              <Fragment key={benefit.name}>
                <div
                  onClick={() =>
                    setOpenRowIndex((currentOpenRowIndex) =>
                      index === currentOpenRowIndex ? undefined : index
                    )
                  }
                  className={styles.row}
                >
                  <FlexRow
                    className={styles.rowLegend}
                    style={{ gridColumn: 1 }}
                  >
                    <Typography
                      variant="body3"
                      className={styles.rowLegendTitle}
                    >
                      {t(benefit.title)}
                    </Typography>
                  </FlexRow>
                  <FlexRow className={styles.rowIcon} style={{ gridColumn: 2 }}>
                    <Icon
                      name={isOpen ? "chevron-up" : "chevron-down"}
                      size={16}
                      color={Colors.blue300}
                    />
                  </FlexRow>
                  <FlexRow
                    className={styles.rowDescription}
                    style={{ gridColumn: 3 }}
                  >
                    <Typography
                      variant="body3"
                      color="grey500"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {formatProperty(p.benefitIncludes[benefit.name])}
                    </Typography>
                  </FlexRow>
                  <Typography
                    variant="body3"
                    className={styles.rowLegendDescription}
                    style={{
                      maxHeight: isOpen ? 400 : 0,
                      paddingBottom: isOpen ? 16 : 0,
                      gridColumn: "1 / 4",
                      margin: "0 8px",
                    }}
                  >
                    {t(benefit.description ?? "")}
                  </Typography>
                </div>
              </Fragment>
            );
          }),
          <Link
            key="ipid"
            href={t(p.ipid)}
            className={styles.rowIpid}
            style={{ gridColumn: "1 / 4" }}
            target="_blank"
          >
            <Icon name="document-check" size={20} color={Colors.grey500} />
            <Typography variant="body3" color="grey500">
              {t("insurance_packages.item_info")}
            </Typography>
          </Link>,
        ].slice(
          0,
          isExpanded ? config.benefits.length + 1 : numberOfNonExpandedRows
        )}
      </div>
    );
  };

  return (
    <>
      <PackageCard pack={pack} rounded />
      <Gutter size={2} />
      <Tabs
        items={config.packages.map(renderPackage)}
        tabs={config.packages.map((p) => ({
          value: p.title,
          label: t(p.title),
        }))}
        onChangeTabs={(_, index) => setSelectedIndex(index)}
        index={selectedIndex}
      />
      {!expanded && (
        <Button
          variant="outlined"
          icon={isExpanded ? "chevron-up" : "chevron-down"}
          textColor={"blue300"}
          onPress={() => setIsExpanded(!isExpanded)}
          title={
            isExpanded
              ? t("packages.hide_full_list")
              : t("packages.show_full_list")
          }
        />
      )}
    </>
  );
};

export default MobileFullPackages;
